<div class="center" style="position: relative">
  <ngx-file-drop (onFileDrop)="dropped($event)" (onFileLeave)="fileLeave($event)" (onFileOver)="fileOver($event)"
                 accept="image/png, application/pdf, image/jpeg, image/jpg, image/heic, image/heif"
                 contentClassName="file-drop-zone"
                 dropZoneClassName="file-drop-zone" dropZoneLabel="Solte o arquivo aqui">
    <ng-template let-openFileSelector="openFileSelector" ngx-file-drop-content-tmp>
      <button *ngIf="typeButton === 'SECONDARY'" (click)="openFileSelector()" [disabled]="loading" [class.w-100]="width100"
              mat-stroked-button type="button">
        <mat-icon *ngIf="iconButton" matTooltip="Icone">{{ iconButton }}</mat-icon>
        <span [innerHTML]="titleButton"></span>
      </button>
      <button *ngIf="typeButton === 'PRIMARY'" (click)="openFileSelector()" [disabled]="loading" class="w-100"
              mat-raised-button color="primary" type="button">
        <mat-icon *ngIf="iconButton" matTooltip="Icone">{{ iconButton }}</mat-icon>
        <span [innerHTML]="titleButton"></span>
      </button>
      <button (click)="removeFile();$event.stopPropagation();" *ngIf="showRemoveButton" color="primary"
              mat-icon-button
              type="button">
        <mat-icon matTooltip="Remover arquivo">close</mat-icon>
      </button>
    </ng-template>
  </ngx-file-drop>
</div>
