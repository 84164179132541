import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MenuCustom, MenuLayoutService } from '../../../services/menu-layout.service';
import { JwtAuthService } from '../../../services/auth/jwt-auth.service';
import { Person } from '../../../../state/models/person';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { OrganizationService } from '../../../services/organization.service';
import { AutoUnsubscribe, CombineSubscriptions } from '../../../decorators/auto-unsubscribe.decorator';
import { Unsubscribable } from 'rxjs';
import { AmplitudeService } from '../../../services/amplitude.service';
import { Organization } from '../../../../state/models/organization';
import { WhatsappService } from '../../../services/whatsapp.service';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'menu-layout',
  templateUrl: './menu-layout.component.html',
  styles: `
    .text-title {
      text-overflow: ellipsis;
      max-width: 264px;
      overflow: hidden;
    }

    .example-spacer {
      flex: 1 1 auto;
    }

    .toolbar-item-spacer {
      flex: 1 1 auto;
    }

    .app-container {
      height: calc(100vh - 64px);
      margin: 0;

      @media screen and (max-width: 600px) {
        height: calc(100vh - 56px);
      }

      &.full {
        height: 100vh;
      }

      @supports (height: 100dvh) {
        height: calc(100dvh - 64px);
        @media screen and (max-width: 600px) {
          height: calc(100dvh - 56px);
        }
        &.full {
          height: 100dvh;
        }
      }
    }

    .app-sidenav {
      width: 230px;
    }

    .app-sidenav-content {
      height: calc(100vh - 64px);
      @media screen and (min-width: 1200px) {
        overflow: auto;
      }
      @media screen and (max-width: 600px) {
        height: calc(100vh - 56px);
      }

      &.full {
        height: 100vh;
      }

      @supports (height: 100dvh) {
        height: calc(100dvh - 64px);
        @media screen and (max-width: 600px) {
          height: calc(100dvh - 56px);
        }
        &.full {
          height: 100dvh;
        }
      }
    }

    mat-nav-list {
      height: 100%;
      display: flex;
      flex-direction: column;

      mat-list-item {
        .mat-mdc-list-item-unscoped-content {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }


  `
})
@AutoUnsubscribe()
export class MenuLayoutComponent implements OnInit, AfterViewChecked, OnDestroy {
  toolbarNav: boolean;
  user: Person;
  @ViewChild('sidenav') drawer: MatSidenav;
  organization: Organization;
  @CombineSubscriptions()
  private subscriptions: Unsubscribable;
  menuCustom: MenuCustom;
  isDestktop = this.utilsService.isDesktop(1200);

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isDestktop = this.utilsService.isDesktop(1200);
  }


  constructor(
    public utilsService: UtilsService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private jwtAuthService: JwtAuthService,
    private menuLayoutService: MenuLayoutService,
    private organizationService: OrganizationService,
    private amplitudeService: AmplitudeService,
    private whatsappService: WhatsappService,
  ) {
    this.organization = organizationService.getOrganization();

    this.subscriptions = this.jwtAuthService.user$.asObservable().subscribe({
      next: () => {
        this.user = jwtAuthService.getUser();
        this.organization = organizationService.getOrganization();
      }
    });
    this.subscriptions = this.menuLayoutService.layoutConf$.subscribe(config => {
      this.toolbarNav = config.toolbarNav;
      this.menuCustom = config?.menuCustom;
      this.user = jwtAuthService.getUser();
    });

    this.subscriptions = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(routeChange => {
        if (this.drawer) {
          this.drawer?.close().then();
        }
        this.organization = organizationService.getOrganization();
      });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  logout() {
    this.jwtAuthService.logout(true);
  }

  anvisaRegulation() {
    window.open('https://www.marsaude.net/regulacao', '_blank');

  }

  openWhatsApp() {
    this.amplitudeService.sendEvent('contact_support_clicked', {
      stepName: location.pathname,
    });
    const msg = 'Olá, estou com dúvidas no processo preventivo e gostaria de ajuda.';
    this.whatsappService.sendMessage(msg, { oId: true });
  }

  toggle(sidenav) {
    if (this.user) {
      sidenav.toggle();
    }
  }

  actionFirstButton() {
    this.menuLayoutService.menuCustomSubject.next({ firstButtonClick: true });
  }

  actionLastButton() {
    this.menuLayoutService.menuCustomSubject.next({ lastButtonClick: true });
  }
}
